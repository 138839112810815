<template>
  <div class="video-grid">
    <div v-if="isLoading" class="loading-container">
      <div class="loading-spinner"></div>
      <div class="loading-text">加载中...</div>
    </div>
    <div v-else class="video-item" v-for="video in videos" :key="video.name" @click="navigateToVideo(video)">
      <div class="thumbnail-container">
        <img :src="getThumbnail(video)" alt="Video Thumbnail" class="video-thumbnail" width="200" height="150" />
        <div class="icon-overlay">
          <i v-if="video.is_dir" class="fas fa-folder folder-icon"></i>
          <i v-else class="fas fa-play-circle video-icon"></i>
        </div>
      </div>
      <div class="video-info">
        <h3 class="video-name">{{ getVideoShowName(video) }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import {join} from 'path';
export default {
  data() {
    return {
      videos: [],
      thumbs: [],
      apiPath: 'api/fs/list',
      // currentPath:'/tianyi/study/赞美/赞美之泉/讚美之泉敬拜讚美專輯(01) 讓讚美飛揚 Let Praise Arise',
      currentPath: '/tianyi/study/赞美/',
      indexPath: '/tianyi/study/赞美/',
      isLoading: false,
    };
  },
  props: {
    currentPropsPath:{
      type: String,
      default: '/tianyi/study/赞美/'
    }
  },
  watch: {
    '$route': {
      handler: 'resetData',
      immediate: true
    },
    currentPropsPath(newPath) {
      console.log(newPath);
      this.resetData();
    }
  }
  ,
  mounted() {
    console.log(this.$appName); // 输出：'My App'
    this.resetData();
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        // behavior: "smooth" // 可以使滚动过程平滑
      });
    },
    resetData() {
      this.isLoading = true;

      if (this.$route.query && this.$route.query.folder_path_base64) {
        console.log(this.$route.query.folder_path_base64);
        this.currentPath = this.diyDecodePaht(this.$route.query.folder_path_base64);
        console.log(this.currentPath);
      } else {
        this.currentPath = this.currentPropsPath;
      }

      const regex = /\/([^/]+)\/$/;
      const match = this.currentPath.match(regex);

      if (match && match[1]) {
        const result = match[1];
        document.title = result;
      } else {
        console.log('未找到匹配的字符串');
      }

      const requestData = {
        path: this.currentPath,
        password: "",
        page: 1,
        per_page: 0,
        refresh: false
      };

      axios.post(this.$apiHost + this.apiPath, requestData)
        .then(response => {
          var itemList = response.data.data.content;
          var videos = [];
          var thumbs = [];
          //遍历
          for (var i = 0; i < itemList.length; i++) {
            //判断是否为mp4结尾
            if (itemList[i].name.endsWith(".mp4") || itemList[i].is_dir) {
              videos.push(itemList[i]);
            }
            if (itemList[i].name.endsWith(".jpg") || itemList[i].name.endsWith(".webp")) {
              thumbs.push(itemList[i]);
            }
            //判断是否已
          }
          this.videos = videos;
          this.thumbs = thumbs;
          console.log("thumbs:");
          console.log(thumbs);
          // if (this.$route.query.scrollToTop) {
          //   this.scrollToTop();
          // }
          this.scrollToTop();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getThumbnail(video) {
      var videoName = this.getVideoName(video);
      videoName = encodeURIComponent(videoName);
      var url = this.$picPathPrefix + videoName + ".jpg";
      return url;
    },
    getVideoName(video) {
      return video.name.replace(/\.mp4$/, "");
    },
    getVideoShowName(video) {
      var videoName = this.getVideoName(video);
      return videoName.replace(/^\d+-/, '');
    },
    diyEncodePath(path) {
      return encodeURIComponent(path.replaceAll('/', '___').replaceAll(' ', 'xxx'));
    },
    diyDecodePaht(path)
    {
      return decodeURIComponent(path).replaceAll('___', '/').replaceAll('xxx', ' ');
    },
    navigateToVideo(video) {
      console.log(video)
      if (video.is_dir) {
        var dest_folder_path = this.currentPath + video.name + '/';
        console.log(dest_folder_path);
        //base64
        var path_base64 = this.diyEncodePath(dest_folder_path);
        // this.$router.push({path:`/video_list/${path_base64}`});
        this.$router.push({ name: 'VideoGrid', query: { folder_path_base64: path_base64} });
      } else {
        console.log('push');
        console.log(this.$router);

        var video_file_path = this.currentPath  + video.name;
        video_file_path = video_file_path.replace('.mp4', '');
        var video_file_path_encoded = encodeURIComponent(video_file_path);
        this.$router.push({path:`/video_player/${video_file_path_encoded}`});
      }
    },
  },
};

</script>

<style scoped>
.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 将网格分为两列，每列平均占据剩余空间 */
  gap: 20px;
  /* 设置列间隔，根据需要进行调整 */
}

.video-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

.video-item:hover {
  transform: translateY(-4px);
}

.video-item:hover .video-thumbnail {
  transform: scale(1.05);
}

.video-item:hover .icon-overlay {
  transform: scale(1.1);
}

.video-item[data-is-dir="true"] {
  cursor: pointer;
}

.video-item[data-is-dir="true"]:hover .folder-icon {
  transform: none;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.icon-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background 0.3s ease;
}

.video-item:hover .icon-overlay {
  transform: scale(1.1);
  background: rgba(0, 0, 0, 0.2);
}

.folder-icon {
  color: rgba(255, 215, 0, 0.4);
  font-size: 16px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.video-icon {
  color: rgba(255, 255, 255,  0.4);
  font-size: 16px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.video-info {
  margin-top: 0px;
  text-align: center;
}

.video-name {
  font-size: 13px;
  font-weight: 500;
  color: #2c3e50;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  padding: 0 8px;
}

.video-date,
.video-size {
  font-size: 14px;
  margin-top: 5px;
}

.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading-text {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  margin: 0 auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>